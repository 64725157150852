import React, { useContext, useState } from 'react';
import { Image, IconButton } from '@naturacosmeticos/natds-web';
import { I18nContext } from '../../config/i18n';
import Typography from '../typography/Typography';
import Dialog from '../dialog/Dialog';
import DialogTitle from '../dialog-title/DialogTitle';
import DialogContent from '../dialog-content/DialogContent';
import FlexBox from '../flex-box/FlexBox';
import contentCatalog from '../../assets/contentCatalog.png';
import catalogImg from '../../assets/catalog.png';
import whatsImg from '../../assets/whatsapp.png';
import Icon from '../icon/Icon';

const ReadMoreCatalog = ({ openModal = false, toClose }) => {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 610);

  return (
    <>
      <Dialog
        maxWidth="sm"
        onEscapeKeyDown={toClose}
        onBackdropClick={toClose}
        open={openModal}
      >
        <DialogTitle>
          <FlexBox justifyContent="space-between" alignItems="center">
            <Typography>
              {translate('home.catalog.modal.title')}
            </Typography>
            <IconButton
              onClick={toClose}
              size="medium"
            >
              <Icon onClick={toClose} size="tiny" name="outlined-navigation-close" />
            </IconButton>
          </FlexBox>
        </DialogTitle>
        <DialogContent>
          <FlexBox
            justifyContent={isMobile ? 'space-between' : 'center'}
            alignItems="center"
            margin={isMobile ? '0px 0px 25px 0px' : '0px 0px 50px 0px'}
            gap="5px"
          >
            <Typography fz={isMobile ? '14px' : '16px'} fw="500">
              {translate('home.catalog.modal.catalog.title')}
            </Typography>
            <FlexBox gap="5px" width="max-content">
              <Image src={catalogImg} width={20} />
              <Image src={whatsImg} width={20} />
            </FlexBox>
          </FlexBox>
          <FlexBox gap="15px" p={isMobile ? '0px 0px 20px 0px' : '0px 0px 40px 0px'}>
            <FlexBox flex="0" display={isMobile && 'none'}>
              <Image width="auto" height={287} src={contentCatalog} />
            </FlexBox>
            <FlexBox flexDirection="column" gap="15px" flex="1">
              <Typography fz={isMobile && '12px'} variant="body2">
                {translate('home.catalog.modal.catalog.content.part1')}
              </Typography>
              <Typography fz={isMobile && '12px'} variant="body2">
                {translate('home.catalog.modal.catalog.content.part2')}
              </Typography>
              <Typography fz={isMobile && '12px'} variant="body2">
                {translate('home.catalog.modal.catalog.content.part3')}
              </Typography>
              <Typography fz={isMobile && '12px'} variant="body2">
                {translate('home.catalog.modal.catalog.content.part4')}
                {' '}
                😉
              </Typography>
            </FlexBox>
          </FlexBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReadMoreCatalog;
