import ky from 'ky';
import config from '../config/config';
import { generateHash } from '../utils/encrypt-utils';
// eslint-disable-next-line import/no-cycle
import { getCustomerData } from '../store/model/main-model';

const visitFetcher = () => {
  const customerData = getCustomerData();
  const { hardCodedCustomerId } = config.general;
  const { hardCodedCustomerNo } = config.general;
  return ky.create({
    timeout: 60000,
    prefixUrl: config.api.visitUrl || config.api.url,
    headers: {
      client_id: config.api.clientId,
      client_secret: config.api.clientSecret,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      token: sessionStorage.getItem('sso_token'),
      tenant_id: config.bffApi.tenantId,
      customer_id: hardCodedCustomerId || customerData.customer_id,
      customer_no: hardCodedCustomerNo || customerData.customer_no,
      personId: customerData.customer_no,
      cnid: generateHash(customerData.customer_no),
    },
  });
};

export default visitFetcher;
