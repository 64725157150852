import fetcherTag from '../api/fetcherTag';
import config from '../config/config';

export const registerActionWithTagApi = async (action, personCnCode, digitalStoreId) => {
  const body = {
    date: Date.now(),
    country: config.general.country,
    timezoneOffset: '-03:00',
    consultantCycle: '0',
    magazineYear: 0,
    magazineCycle: 0,
    cnId: personCnCode,
    action,
    sessionId: '0',
    shopId: digitalStoreId || '',
    createdAt: Date.now(),
  };
  try {
    await fetcherTag().post('', { json: body });
  } catch (e) {
    console.log('error to push tag', e.message);
  }
};
