/* eslint-disable max-len */
import React, { useEffect, useContext } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { TextField } from '@naturacosmeticos/natds-web';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import config from '../../config/config';
import LoginModalError from '../../components/login-modal-error/LoginModalError';
import { I18nContext } from '../../config/i18n';
import Typography from '../../components/typography/Typography';
import Button from '../../components/button/Button';
import FlexBox from '../../components/flex-box/FlexBox';
import { LoginStyle } from './Login.style';
import { required, validateEmail } from '../../utils/form-utils';
import Card from '../../components/card/Card';
import ExpiredLoginError from '../../components/login-modal-error/ExpiredLoginError';

function Login() {
  const isNaturaArgentina = config.general.country === 'ar' && config.general.company === 'natura';
  const displayLogin = config.auth.loginConfig === 'natura_login';
  const { registerPage } = config.bffApi;
  const { error, errorSection } = useStoreState((state) => state.main);
  const { handleBffLogin } = useStoreActions((actions) => actions.main);
  const { control, formState, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const classes = LoginStyle();
  const history = useHistory();
  const { translate } = useContext(I18nContext);

  useEffect(() => {
    if (!displayLogin) {
      window.location.href = config.auth.loginUrl;
    }
  }, []);

  async function onSubmit(data) {
    if (!formState.errors.email && !formState.errors.password) {
      await handleBffLogin({ login: data.email, password: data.password });
      const redirectUrl = localStorage.getItem('redirect_url');
      history.push(redirectUrl || '/');
    }
  }

  return (
    <>
      {errorSection && <ExpiredLoginError />}
      {error && <LoginModalError />}
      {displayLogin
        && (
          <FlexBox flexDirection="column" className={classes.container} justifyContent="center">
            <Card p="20px" border="none">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  render={({ field, fieldState }) => (
                    <>
                      <Typography p="10px 0" error={fieldState.error}>{translate(('login.email'))}</Typography>
                      <TextField
                        id="email"
                        {...field}
                        state={fieldState.error ? 'error' : undefined}
                        style={{ marginRight: '10px' }}
                        placeholder={translate(('login.email'))}
                        helpText={
                          fieldState.error && translate(fieldState.error.message)
                        }
                      />
                    </>
                  )}
                  rules={{
                    required: required.message,
                    pattern: validateEmail,
                  }}
                  control={control}
                  name="email"
                />
                <Controller
                  render={({ field, fieldState }) => (
                    <>
                      <Typography p="10px 0" error={fieldState.error}>{translate('login.password')}</Typography>
                      <TextField
                        className={classes.inputPassword}
                        id="password"
                        type="password"
                        {...field}
                        style={{ marginRight: '10px' }}
                        placeholder="******"
                        state={fieldState.error ? 'error' : undefined}
                        helpText={
                          fieldState.error && translate(fieldState.error.message)
                        }
                      />
                    </>
                  )}
                  rules={{
                    required: required.message,
                    validate: (value) => value.length >= 8,
                  }}
                  control={control}
                  name="password"
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '20px' }}
                  type="submit"
                  className={classes.loginButton}
                >
                  {translate(('login.submit'))}
                </Button>
              </form>
            </Card>
            {registerPage && (
              <Button style={{ marginTop: '20px' }} onClick={() => window.location.replace(registerPage)}>
                {translate(('login.register'))}
              </Button>
            )}
            <Button style={{ marginTop: '20px' }} onClick={() => history.push('/forgot-password')}>
              {translate(('login.forgotPassword'))}
            </Button>
            {!isNaturaArgentina && (
              <Button style={{ marginTop: '20px' }} onClick={() => history.push('/first-access')}>
                {translate(('login.first_access'))}
              </Button>
            )}
          </FlexBox>
        )}
    </>
  );
}

export default Login;
