import React, { useContext, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { makeStyles } from '@naturacosmeticos/natds-web';

import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Icon from '../../components/icon/Icon';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import Button from '../../components/button/Button';
import LinkComponent from '../../components/link-component/LinkComponent';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';
import config from '../../config/config';

const digitalSpaceBaseUrl = process.env.REACT_APP_DIGITAL_SPACE_BASE_URL;

const useStyles = makeStyles(() => ({
  nameUser: {
    fontsize: '20px',
    whiteSpace: 'nowrap',
    width: '-webkit-fill-available',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const Welcome = () => {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);

  const {
    loading,
    facebookLink,
    instagramLink,
    personFullName,
    whatsWappNumber,
    personDigitalSpaceName,
  } = useStoreState((state) => state.main);

  const spaceDigital = `${digitalSpaceBaseUrl}${personDigitalSpaceName}`;
  const isArgentina = config.general.country === 'ar';
  const classes = useStyles();

  function openInstagram() {
    window.open(instagramLink, '_blank');
  }

  function openFacebook() {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${spaceDigital}`, '_blank');
  }

  function openWhats() {
    window.open(`https://api.whatsapp.com/send?text=${spaceDigital}`, '_blank');
  }

  return (
    <>
      <Card>
        {loading ? (
          <FlexBox alignItems="center" justifyContent="center">
            <ProgressIndicator size={24} />
          </FlexBox>
        ) : (
          <FlexBox
            alignItems="flex-start"
            flexDirection="column"
            justifyContent="space-between"
            p="5px 0 5px 0"
          >
            <FlexBox alignItems="flex-end" justifyContent="start">
              <Typography
                variant="h6"
                m="0px 5px 4px 0px"
              >
                {translate('home.welcome.welcome_msg')}
                ,
              </Typography>
              <Typography
                variant="h5"
                className={isMobile ? classes.nameUser : ''}
              >
                {personFullName}
              </Typography>
            </FlexBox>

            <FlexBox alignItems="center" gap="10px" flexWrap="wrap">
              <Typography
                variant="h6"
              >
                {translate('home.digital_space.share_title')}
              </Typography>
              <FlexBox width="300px" alignItems="center" gap="10px">
                {whatsWappNumber && (
                <Button onClick={openWhats} style={{ minWidth: '10px' }} variant="text">
                  <Icon
                    name="filled-brand-whatsapp"
                    size="tiny"
                  />
                </Button>
                )}
                {instagramLink && !isArgentina && (
                <Button onClick={openInstagram} style={{ minWidth: '10px' }} variant="text">
                  <Icon
                    name="filled-brand-instagram"
                    size="tiny"
                  />
                </Button>
                )}
                {facebookLink && (
                <Button onClick={openFacebook} style={{ minWidth: '10px' }} variant="text">
                  <Icon
                    name="filled-brand-facebook"
                    size="tiny"
                  />
                </Button>
                )}
              </FlexBox>
              {digitalSpaceBaseUrl && personDigitalSpaceName && (
              <LinkComponent
                style={isMobile ? {
                  whiteSpace: 'nowrap',
                  width: '-webkit-fill-available',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                } : {}}
                color="default"
                target="_blank"
                underline="none"
                href={`${digitalSpaceBaseUrl}${personDigitalSpaceName}`}
                variant="body2"
              >
                {`${digitalSpaceBaseUrl}${personDigitalSpaceName}`}
              </LinkComponent>
              )}
            </FlexBox>
          </FlexBox>
        )}
      </Card>
    </>
  );
};

export default Welcome;
