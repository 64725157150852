import React, { useContext, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Image } from '@naturacosmeticos/natds-web';

import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Icon from '../../components/icon/Icon';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import Button from '../../components/button/Button';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';
import catalogImg from '../../assets/catalog.png';
import ReadMoreCatalog from '../../components/readmore-catalog/ReadMoreCatalog';
import { registerActionWithTagApi } from '../../tag-actions/catalogInteraction';

const Catalog = () => {
  const { translate } = useContext(I18nContext);

  const [isMobile] = useState(window.innerWidth < 610);

  const {
    loading,
    personDigitalSpaceName,
    personCnCode,
    catalogLink,
  } = useStoreState((state) => state.main);

  const [isCopy, setIsCopy] = useState(false);
  const [open, setOpen] = useState(false);

  const resetCopyButton = () => {
    setIsCopy(false);
  };

  function handleOpenFacebook() {
    // eslint-disable-next-line max-len
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${catalogLink}`, '_blank');
    registerActionWithTagApi('PANEL_NATURA_WCB_FACEBOOK_SHARE', personCnCode, personDigitalSpaceName);
  }

  function handleOpenWhats() {
    window.open(`https://api.whatsapp.com/send?text=${catalogLink}`, '_blank');
    registerActionWithTagApi('PANEL_NATURA_WCB_WHATSAPP_SHARE', personCnCode, personDigitalSpaceName);
  }

  function handleReadMoreOpen() {
    setOpen(!open);
    if (!open) {
      registerActionWithTagApi('PANEL_NATURA_WCB_KNOWMORE', personCnCode, personDigitalSpaceName);
    }
  }

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(catalogLink);
      setIsCopy(true);
      setTimeout(resetCopyButton, 2000);
      registerActionWithTagApi('PANEL_NATURA_WCB_COPYLINK', personCnCode, personDigitalSpaceName);
    } catch (err) {
      console.error('Erro ao copiar: ', err);
    }
  };

  return (
    <>
      <ReadMoreCatalog openModal={open} toClose={handleReadMoreOpen} />
      <Card>
        {loading ? (
          <FlexBox alignItems="center" justifyContent="center">
            <ProgressIndicator size={24} />
          </FlexBox>
        ) : (
          <FlexBox
            alignItems="flex-start"
            flexDirection="column"
            p="5px 0 5px 0"
            gap="30px"
          >
            <FlexBox alignItems="center" gap="8px">
              <Image src={catalogImg} width={28} />
              <Typography fontWeight="bold" variant="body2" color="#F4AB34">{translate('home.catalog.new')}</Typography>
            </FlexBox>
            <FlexBox alignItems="center" gap={isMobile ? '21px' : '10px'} flexWrap="wrap">
              <Typography
                variant={isMobile ? 'h7' : 'h6'}
                fontWeight={500}
              >
                {translate('home.catalog.msg')}
              </Typography>
              <FlexBox width="300px" alignItems="center" gap="10px">
                <Button
                  onClick={handleOpenWhats}
                  style={{ minWidth: '10px' }}
                  // p="0 5px"
                  variant="text"
                >
                  <Icon
                    name="filled-brand-whatsapp"
                    size="tiny"
                  />
                </Button>
                <Button onClick={handleOpenFacebook} style={{ minWidth: '10px' }} variant="text">
                  <Icon
                    name="filled-brand-facebook"
                    size="tiny"
                  />
                </Button>
                {isCopy ? (
                  <FlexBox maxWidth="max-content" alignItems="center" gap="3px">
                    <Typography fz={isMobile && '14px'} color="#5C9D37">
                      {translate('home.catalog.copy.alert')}
                    </Typography>
                    <Icon fontColor="#5C9D37" name="outlined-action-check" size="tiny" />
                  </FlexBox>
                ) : (
                  <Button
                    onClick={handleCopyToClipboard}
                    variant="outlined"
                    color="primary"
                    size="medium"
                  >
                    {translate('home.catalog.copy.button')}
                  </Button>
                )}
              </FlexBox>
            </FlexBox>
            <Button variant="text" size="medium" onClick={handleReadMoreOpen}>
              {translate('home.catalog.read.more')}
            </Button>
          </FlexBox>
        )}
      </Card>
    </>
  );
};

export default Catalog;
