import ky from 'ky';
import config from '../config/config';

const fetcherTag = () => ky.create({
  timeout: 60000,
  prefixUrl: config.apiTag.tagUrl,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: config.apiTag.tagAuthToken,
    brand: config.general.company,
    os: 'WEB',
  },
});

export default fetcherTag;
