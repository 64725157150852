import React, { useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import FlexBox from '../../components/flex-box/FlexBox';
import Page from '../../components/page/Page';
import ErrorScreen from '../../components/error-screen/ErrorScreen';
import Welcome from './Welcome';
import RehabilitateStore from './RehabilitateStore';
import Banner from './Banner';
import config from '../../config/config';
import Catalog from './Catalog';

const Home = () => {
  const [isMobile] = useState(window.innerWidth < 1080);
  const { error, customerNo, catalogEnabled } = useStoreState(
    (state) => state.main,
  );
  const { getCnData, handleDataBaner } = useStoreActions(
    (actions) => actions.main,
  );
  const showMP = config.general.showMpInHome !== 'false';
  const showCatalog = config.general.showCatalog === 'true';

  useEffect(() => {
    if (!customerNo) return;
    getCnData({ customerNo });
    handleDataBaner();
  }, [customerNo]);

  return (
    <>
      <Page>
        {error && <ErrorScreen />}
        {!error && (
          <FlexBox flexDirection={isMobile ? 'column' : 'row'} m="0px 0px">
            <FlexBox
              minWidth="300px"
              m={isMobile && '0px 0px'}
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              flex="2"
            >
              {showCatalog && catalogEnabled && (
                <FlexBox
                  justifyContent="center"
                  minWidth="100%"
                  display="block"
                >
                  <Catalog />
                </FlexBox>
              )}
              <FlexBox justifyContent="center" minWidth="100%" display="block">
                <Welcome />
              </FlexBox>
              {showMP && (
                <FlexBox justifyContent="center" minWidth="100%">
                  <RehabilitateStore />
                </FlexBox>
              )}
              <FlexBox
                justifyContent="center"
                minWidth="100%"
                flexDirection="column"
              >
                <Banner />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        )}
      </Page>
    </>
  );
};
export default Home;
