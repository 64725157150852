import ky from 'ky';
import config from '../config/config';

const fecherCatalog = () => ky.create({
  timeout: 60000,
  prefixUrl: config.catalogApi.url,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': config.catalogApi.key,
  },
});

export default fecherCatalog;
