import React, { useContext, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import Button from '../../components/button/Button';
import { I18nContext } from '../../config/i18n';
import config from '../../config/config';
import Icon from '../../components/icon/Icon';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';
import Typography from '../../components/typography/Typography';

const RehabilitateStore = () => {
  const [isMobile] = useState(window.innerWidth < 1080);
  const { handleChangeStatusShop, setOpenReactivationModal } = useStoreActions((actions) => actions.main);
  const { status, loading, personIdCn } = useStoreState((state) => state.main);
  const { translate } = useContext(I18nContext);

  async function handleStoreReactivation() {
    if (config.general.reactivationMode === 'mercadoPago') {
      window.open(config.general.storeReactivation, '_self');
    } else if (config.general.reactivationMode === 'direct') {
      try {
        const consultantInfo = {
          personId: personIdCn,
          storeStatus: 1,
        };
        await handleChangeStatusShop(consultantInfo);
        setOpenReactivationModal({ status: true, message: 'success' });
      } catch (e) {
        setOpenReactivationModal({ status: true, message: 'error' });
      }
    }
  }

  function handleAcessStoreLink() {
    window.open(config.general.acessStoreLink, '_self');
  }

  return (
    <>
      <Card>
        {loading ? (
          <FlexBox alignItems="center" justifyContent="center">
            <ProgressIndicator size={24} />
          </FlexBox>
        ) : (
          <FlexBox
            justifyContent="space-between"
            alignItems={isMobile ? 'flex-start' : 'center'}
            minWidth="100%"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <FlexBox
              justifyContent="flex-start"
              alignItems={isMobile ? 'flex-start' : 'center'}
              flexDirection="column"
            >
              <FlexBox justifyContent="flex-start" alignItems="center">
                <Icon
                  style={{ marginLeft: '-12px' }}
                  name="outlined-action-calendar"
                  size="medium"
                />
                <Typography whiteSpace="nowrap" variant="h5">
                  {translate('home.rehabilitatestore.period_profit')}
                </Typography>
              </FlexBox>
              <FlexBox
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                gap="20px"
                padding="0 10px 10px 10px"
              >
                <Typography>
                  {translate('home.rehabilitatestore.period_profit_explanation')}
                </Typography>
                <Typography>
                  {translate('home.rehabilitatestore.ganancia_del_periodo')}
                </Typography>
                <Typography>
                  {translate('home.rehabilitatestore.ganancia_del_período_debit')
                    .split(' ')
                    .map((word, index) => (word === 'débito' ? (
                      <strong key={index}>{translate('home.rehabilitatestore.ganancia_del_período_debit_word')}</strong>
                    ) : (
                      `${word} `
                    )))}
                </Typography>
                <Typography>
                  {translate('home.rehabilitatestore.ganancia_del_período_credit')
                    .split(' ')
                    .map((word, index) => (word === 'crédito' ? (
                      <strong key={index}>
                        {translate('home.rehabilitatestore.ganancia_del_período_credit_word')}
                      </strong>
                    ) : (
                      `${word} `
                    )))}
                </Typography>
                <Typography>
                  {translate('home.rehabilitatestore.ganancia_del_período_tarjeta_naranja')
                    .split(' ')
                    .map((word, index) => (word === 'Tarjeta' || word === 'Naranja,' ? (
                      <strong key={index}>
                        {`${word} `}
                      </strong>
                    ) : (
                      `${word} `
                    )))}
                </Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox
              justifyContent="flex-start"
              alignItems={isMobile ? 'normal' : 'center'}
              flexDirection="column"
              width={isMobile ? '100%' : 'auto'}
            >
              <Button
                m={isMobile ? '30px 0px 0px 0px' : ''}
                size={isMobile ? 'medium' : 'large'}
                variant="contained"
                fullWidth={!!isMobile}
                style={{ marginLeft: isMobile ? '' : '10px', height: isMobile ? '' : '70px', whiteSpace: 'nowrap' }}
                color={status === 'ACTIVE' ? 'primary' : 'secondary'}
                onClick={() => (status === 'ACTIVE' ? handleAcessStoreLink() : handleStoreReactivation())}
              >
                {status === 'ACTIVE' ? translate('home.acess_account') : translate('home.enable_store')}
              </Button>
            </FlexBox>

          </FlexBox>
        )}
      </Card>
    </>
  );
};

export default RehabilitateStore;
