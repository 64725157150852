import ky from 'ky';
import config from '../config/config';
import { getEncryptPersonId, getPersonId } from '../utils/encrypt-utils';
// eslint-disable-next-line import/no-cycle
import { getCustomerData } from '../store/model/main-model';

const fetcher = () => {
  const customerData = getCustomerData();
  const { hardCodedCustomerId } = config.general;
  const { hardCodedCustomerNo } = config.general;
  return ky.create({
    timeout: 50000,
    prefixUrl: config.bffApi.url,
    headers: {
      client_id: config.bffApi.clientId,
      client_secret: config.bffApi.clientSecret,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      tenant_id: config.bffApi.tenantId,
      customer_id: hardCodedCustomerId || customerData.customer_id,
      customer_no: hardCodedCustomerNo || customerData.customer_no,
      personId: getPersonId(),
      cnid: getEncryptPersonId(),
    },
  });
};
export default fetcher;
