import React, { useContext } from 'react';

import config from '../../config/config';
import { I18nContext } from '../../config/i18n';
import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import LinkComponent from '../../components/link-component/LinkComponent';
import Typography from '../../components/typography/Typography';
import franchineDocChile from '../../assets/pdf/franchine_document_chile.pdf';

const { franchiseAgreementDocumentLink, termsOfUserDocumentLink, mercadoPagoDocumentLink } = config.general;
const isMexico = config.general.country === 'mx';

function Documents() {
  const { translate } = useContext(I18nContext);

  function openFileFranchise() {
    let fileToOpen = franchiseAgreementDocumentLink;
    if (fileToOpen === 'internal_file_ch') {
      fileToOpen = franchineDocChile;
    }
    window.open(fileToOpen, '_blank');
  }

  function openFileRedeNatura() {
    window.open(termsOfUserDocumentLink, '_blank');
  }

  function openFileMercadoPago() {
    window.open(mercadoPagoDocumentLink, '_blank');
  }

  return (
    <>
      <Card>
        <FlexBox m="0px 0px 0px 0px" alignItems="center">
          <Typography variant="h5" color="primary">
            {translate('profile.documents.legal_document')}
          </Typography>
        </FlexBox>
        <FlexBox flexDirection="column" p="0px" m="0px">
          <ul style={{ paddingLeft: '25px', marginTop: '0px', paddingTop: '10px' }}>
            {franchiseAgreementDocumentLink && !isMexico && (
              <li style={{ padding: '2px 0px' }}>
                <LinkComponent
                  color="default"
                  onClick={openFileFranchise}
                  underline="always"
                  href="#"
                  variant="body2"
                >
                  {translate('profile.documents.franchise_agreement')}
                </LinkComponent>
              </li>
            )}
            {termsOfUserDocumentLink && (
              <li style={{ padding: '2px 0px' }}>
                <LinkComponent
                  color="default"
                  onClick={openFileRedeNatura}
                  underline="always"
                  href="#"
                  variant="body2"
                >
                  {translate('profile.documents.terms_of_user')}
                </LinkComponent>
              </li>
            )}
            {mercadoPagoDocumentLink && (
              <li style={{ padding: '2px 0px' }}>
                <LinkComponent
                  color="default"
                  onClick={openFileMercadoPago}
                  underline="always"
                  href="#"
                  variant="body2"
                >
                  {translate('profile.documents.mercado_pago')}
                </LinkComponent>
              </li>
            )}
          </ul>
        </FlexBox>
      </Card>
    </>
  );
}

export default Documents;
