import { action, thunk } from 'easy-peasy';
import fetcher from '../../api/fetcher';

const initialState = {
  coupons: [],
  originalData: { couponEnableList: [] },
  loading: true,
  loadingSummary: true,
  loadingCreateCoupon: false,
  openModal: false,
  error: false,
  modalData: {},
  activeCoupon: 'couponEnableList',
  modalError: false,
  blocklistModal: false,
  genericError: false,
  duplicateCouponModal: false,
  sharedCoupon: null,
  search: null,
  reloadData: null,
};

const couponsModel = {
  ...initialState,
  resetStoreCoupons: action((state) => {
    Object.assign(state, initialState);
  }),
  addCoupons: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.coupons = payload;
    // eslint-disable-next-line no-param-reassign
    state.loading = false;
  }),
  toggleModal: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.openModal = !state.openModal;
  }),
  setSharedCupon: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.sharedCoupon = payload;
  }),
  setSearch: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.search = payload;
  }),
  setModalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.modalData = payload;
  }),
  setReloadData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.reloadData = payload;
  }),
  setActiveCoupon: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.activeCoupon = payload;
    // eslint-disable-next-line no-param-reassign
  //  state.coupons = state.originalData[payload];
  }),
  addOriginalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData = payload;
  }),
  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),
  setLoadingSummary: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loadingSummary = payload;
  }),
  setLoadingCreateCoupon: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loadingCreateCoupon = payload;
  }),
  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
  }),
  setModalError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.modalError = payload;
  }),
  setBlocklistModal: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.blocklistModal = payload;
  }),
  setDuplicateCouponModal: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.duplicateCouponModal = payload;
  }),
  setGenericErrorModal: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.genericError = payload;
  }),
  setCouponsByStatus: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData.couponEnableList = payload;
  }),
  setCouponsSummary: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData.couponActive = payload.couponActive;
    // eslint-disable-next-line no-param-reassign
    state.originalData.couponExpired = payload.couponExpired;
    // eslint-disable-next-line no-param-reassign
    state.originalData.couponUsed = payload.couponUsed;
  }),
  createCoupon: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setLoadingCreateCoupon(true);
    fetcher().patch('coupons/create', { json: payload }).then(
      (response) => {
        if (response.status === 201) {
          actions.setModalError(false);
          actions.setBlocklistModal(false);
          actions.setGenericErrorModal(false);
          actions.setModalData(payload);
          actions.toggleModal(true);
          actions.setLoadingCreateCoupon(false);
        }
      },
    ).catch((err) => {
      if (err.response) {
        err.response.json().then((errorJson) => {
          if (errorJson.message === 'There is an active coupon of same discount percentage.') {
            actions.setModalError({
              message: 'coupons.new_coupon.has-same-percentage-error',
              translate: true,
            });
          // eslint-disable-next-line no-prototype-builtins
          } else if (errorJson.statusDetails.hasOwnProperty('COUPON_ID_ALREADY_EXITS')) {
            actions.setDuplicateCouponModal({});
          } else if (errorJson.statusMessage === 'INVALID_COUPON_CODE_NAME_EXCEPTION') {
            actions.setBlocklistModal({});
          } else {
            actions.setGenericErrorModal({});
          }
        });
      } else {
        actions.setModalError(err);
      }
      console.error('error: -> ', err.message);
      actions.setLoadingCreateCoupon(false);
    });
  }),
  fetchCouponsByStatus: thunk(async (actions, payload) => {
    actions.setCouponsByStatus([]);
    actions.setLoading(true);
    actions.setError(false);
    actions.setActiveCoupon(payload.status);
    fetcher().get(`coupons?personId=${payload.customerNo}&status=${payload.status}`).json()
      .then((result) => {
        actions.setCouponsByStatus(result);
        actions.addCoupons(result);
        actions.setLoading(false);
        actions.setError(false);
      })
      .catch((err) => {
        actions.setError(true);
        actions.setLoading(false);
        console.error('error: -> ', err.message);
      });
  }),
  fetchCouponsSummary: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setLoadingSummary(true);
    actions.setError(false);
    fetcher().get(`coupons/summary?personId=${payload.customerNo}`).json()
      .then((result) => {
        actions.setCouponsSummary({
          couponActive: result && result.active ? result.active : 0,
          couponExpired: result && result.inative ? result.inative : 0,
          couponUsed: result && result.used ? result.used : 0,
        });
        actions.setLoadingSummary(false);
        actions.setError(false);
      })
      .catch((err) => {
        actions.setError(true);
        actions.setLoadingSummary(false);
        console.error('error: -> ', err.message);
      });
  }),
  finishCoupon: thunk(async (actions, payload) => {
    actions.setLoading(true);
    fetcher().patch('coupons/invalidate', { json: payload }).then(() => {
      actions.setModalError(false);
      actions.setReloadData(new Date().getTime());
    })
      .catch((err) => {
        actions.setLoading(false);
        actions.setModalError(true);
        console.error('error: -> ', err.message);
      });
  }),
  filterCoupons: action((state) => {
    const { search, originalData } = state;
    if (search) {
      // eslint-disable-next-line no-param-reassign
      state.coupons = originalData.couponEnableList.filter((item) => (
        item.code.toUpperCase().indexOf(search.toUpperCase()) > -1
      ));
    } else {
      // eslint-disable-next-line no-param-reassign
      state.coupons = originalData.couponEnableList;
    }
  }),
};
export default couponsModel;
